import { dateOnly } from "utils/types";
import {
  ListDto,
  PrivacySettingsDto,
  PrivacySettingsRequest,
} from "./commonTypes";
import { UserOfSearchWithActiveStatus } from "./usersTypes";
import { AchievementsEventsStatuses } from "./achievementEventsTypes";

export enum VisibilityType {
  Visible = "Visible",
  HiddenUntilStart = "HiddenUntilStart",
}

export type AchievementCategoriesListDto = ListDto<AchievementCategoryItemDto>;

export type AddAchievementCategoryRequest = {
  name: string;
  description: string;
  color: string;
  symbol: string;
};

export type AddAchievementCategoryResponse = {
  category: AchievementCategoryItemDto;
};

export type EditAchievementsCategoryRequest = AddAchievementCategoryRequest;

export type AchievementCategoryItemDto = {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  color: string;
  symbol: string;
};

export type AchievementCategoryBaseDto = {
  categoryId: number;
  categoryName: string;
};

export type AchievementCategorySearchDto = {
  id: number;
  name: string;
};

export enum AchievementFilterStatus {
  open = "OpenToApply",
  planned = "Planned",
  applied = "Applied",
}

export enum AchievementStatus {
  active = "Active",
  finished = "Finished",
}

export type AchievementDto = {
  id: number;
  name: string;
  previewImageUrl: string;
  categories: AchievementCategoryItemDto[];
  shortDescription: string;
  fullDescription: string;
  isActive: boolean;
  startDate: dateOnly | null;
  visibilityType: string | null;
  activeUntil: dateOnly | null;
  coins: number;
  isPromoted: boolean;
  isPrivate: boolean;
  privacySettings: PrivacySettingsDto | null;
  uploadDocuments: boolean;
  detailsParticipation: boolean;
  status: AchievementStatus;
  filterStatus: AchievementFilterStatus;
  userApplicantStatus: AchievementsEventsStatuses | null;
};

export type AchievementListResponse = ListDto<AchievementDto>;

export type ApprovedParticipantsResponse = {
  participantsCount: number;
};

export type CreateAchievementRequestBody = PrivacySettingsRequest & {
  name: string;
  categoryIds: number[];
  shortDescription: string;
  fullDescription: string;
  startDate: dateOnly | null;
  visibilityType: string | null;
  activeUntil: dateOnly | null;
  coins: number;
  includeInNewsFeed: boolean;
  detailsParticipation: boolean;
  uploadDocuments: boolean;
};

export type CreateAchievementResponse = {
  achievement: AchievementDto;
};

export type EditAchievementResponse = CreateAchievementResponse;

export type AchievementParticipantsODataDto = {
  items: UserOfSearchWithActiveStatus[];
  totalItemCount: number | null;
};
export enum AchievementParticipantsTabs {
  all = "all",
  myTeam = "myTeam",
}

export type ActiveAchievementsCount = {
  count: number;
};

export type ActivePlayersCount = {
  count: number;
};
