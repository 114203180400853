import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import AddPersonToGroupModal from "./AddPersonToGroupModal";
import { useAppSelector } from "store";
import { Link } from "react-router-dom";
import { Loader, LoaderSize } from "utils/components";
import { getCropTitle } from "utils/methods/getCropTitle";

export const ListPersonGroups: FC = () => {
  const { t } = useTranslation("employeePage");
  const { t: tGroup } = useTranslation("groupManagement");
  const { t: tCommon } = useTranslation("common");

  const [isAddPersonToGroupModal, setIsAddPersonToGroupModal] = useState(false);
  const { data, errorMessage, isLoading } = useAppSelector(
    (state) => state.groupManagement.groupsByUserData
  );

  const toggleAddPersonToGroup = () => {
    setIsAddPersonToGroupModal((prev) => !prev);
  };
  return (
    <>
      <div className="listPersonGroups">
        <div className="d-flex m-btm">
          <h1 className="main-title">{t("personGroups")}</h1>
          <button className="btn-primary" onClick={toggleAddPersonToGroup}>
            {t("addToGroup")}
          </button>
        </div>

        <div className="table-wrap form-style">
          {isLoading && !data && (
            <div className="loader-container">
              <Loader size={LoaderSize.Small} />
            </div>
          )}
          {errorMessage && (
            <div className="new-wrapper error">{errorMessage}</div>
          )}
          {data && !data.length && (
            <p className="no-content-message">
              {tGroup("noUserGroupToDisplay")}
            </p>
          )}
          {data?.length ? (
            <>
              <div className="table-inner table-header">
                <div className="item-col item-th">
                  <span>{tGroup("groupName_field")}</span>
                </div>
                <div className="item-col item-th">
                  <span>{tCommon("description")}</span>
                </div>
                <div className="item-col item-th">
                  <span>{tCommon("type")}</span>
                </div>
              </div>

              {data.map((group) => {
                const { name, description, isDynamic, id } = group;

                return (
                  <Link
                    key={id}
                    className="table-inner new-table-inner"
                    to={`/group-management/groups/${group.id}`}
                  >
                    <div className="item-col d-flex item-col-first-el">
                      <span>{getCropTitle(name, 25)}</span>
                    </div>

                    <div className="item-col">
                      <span>{getCropTitle(description, 60)}</span>
                    </div>

                    <div className="item-col">
                      <span>{isDynamic ? "dynamic" : "static"}</span>
                    </div>
                  </Link>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
      <AddPersonToGroupModal
        isModalOpen={isAddPersonToGroupModal}
        onCloseModal={() => {
          setIsAddPersonToGroupModal(false);
        }}
      />
    </>
  );
};
