import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Icon, Loader, LoaderSize } from "../../../utils/components";
import {
  setUsersStructureBlockFilters,
  setUsersStructureData,
} from "../../../store/reducers/employeePageReducers/employeePersonalInfoReducer";
import { useParams } from "react-router-dom";
import { getUsersStructureData } from "../../../store/thunks/employeePage/employeePageThunks";
import StructureCard from "./StructureCard";
import { Whisper } from "rsuite";
import classNames from "classnames";
import { OverlayTriggerHandle } from "rsuite/esm/internals/Overlay/OverlayTrigger";
import { useIsCurrentUser } from "../../../utils/hooks/useIsCurrentUser";
import FeedbackRightPanel from "../employee-achievements/FeedbackRightPanel";
import { UserOfSearch } from "../../../api/types/usersTypes";
import RequestFeedbackAnimation from "../employee-achievements/RequestFeedbackAnimation";
import { handleRequestFeedback } from "../../../utils/methods/handleRequestFeedback";
import { usePermissionCheck } from "../../../utils/hooks";
import { AppPermissions } from "../../../utils/consts";

enum StructureTabs {
  Manager = "manager",
  DirectReports = "directReports",
  MyTeam = "myTeam",
}

type StructureTab = {
  value: StructureTabs;
  label: string;
  className: string;
};

const StructureBlock: FC = () => {
  const dispatch = useAppDispatch();
  const triggerRef = useRef<OverlayTriggerHandle | null>(null);
  const { t } = useTranslation("employeePage");
  const { t: tCommon } = useTranslation("common");
  const { t: tAchievements } = useTranslation("employeeAchievements");
  const { userId } = useParams();
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.employeePage.employeeData
  );
  const {
    isLoading: isUsersLoading,
    data: usersData,
    errorMessage: errorUsers,
  } = useAppSelector(
    (state) => state.employeePage.usersStructureData.usersList
  );
  const isCurrentUser = useIsCurrentUser();
  const currentUserId = useAppSelector((state) => state.auth.profile?.id);
  const [activeTab, setActiveTab] = useState<StructureTabs>(
    StructureTabs.Manager
  );
  const [isSendCheersModalOpen, setIsSendCheersModalOpen] = useState(false);
  const [badgeMessageReceiver, setBadgeMessageReceiver] =
    useState<UserOfSearch | null>(null);
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  const isAdmin = usePermissionCheck([
    AppPermissions.ViewCompanyOrgStructure,
    AppPermissions.ViewAndEditUserEmployeeCards,
    AppPermissions.EmployeeCard_ViewUsersEmployeeCard,
  ]);

  const tabs: StructureTab[] = [
    {
      value: StructureTabs.Manager,
      label: t("structure_manager"),
      className: "pageTabs__link",
    },
    {
      value: StructureTabs.DirectReports,
      label: t("structure_directReports"),
      className: "pageTabs__link pageTabs__popoverLink",
    },
    {
      value: StructureTabs.MyTeam,
      label: t("structure_teammates"),
      className: "pageTabs__link pageTabs__popoverLink",
    },
  ];

  const handleChangeTab = async (tabValue: StructureTabs) => {
    if (!userId) return;

    if (tabValue === StructureTabs.DirectReports) {
      dispatch(
        setUsersStructureBlockFilters({
          // filter corresponds with field in database for using odata endpoints
          filterBy: ["ReportsToUserId", "ManagerId"],
          userId: +userId,
        })
      );
      dispatch(getUsersStructureData());
    }

    if (tabValue === StructureTabs.MyTeam) {
      if (data && data.basicInfo.manager) {
        dispatch(
          setUsersStructureBlockFilters({
            filterBy: ["ManagerId"],
            userId: data.basicInfo.manager.id,
          })
        );
        dispatch(getUsersStructureData());
      } else {
        dispatch(setUsersStructureData([]));
      }
    }

    setActiveTab(tabValue);
  };

  const speaker = (
    <div className="customPopover">
      {tabs.slice(1).map((tab) => (
        <li
          key={tab.value}
          className={classNames(`${tab.className}`, {
            selected: tab.value === activeTab,
          })}
          onClick={() => {
            triggerRef.current?.close();
            handleChangeTab(tab.value);
          }}
        >
          {tab.label}
        </li>
      ))}
    </div>
  );

  const sendUserCheers = (receiver: UserOfSearch) => {
    setIsSendCheersModalOpen(true);
    setBadgeMessageReceiver(receiver);
  };

  const StructureBlockCard = (user: UserOfSearch) => {
    return (
      <StructureCard
        user={user}
        isMenuRender={isAdmin || currentUserId !== user.id}
      >
        {currentUserId !== user.id && (
          <>
            <button
              type="button"
              className="customPopover__btn"
              onClick={() => sendUserCheers(user)}
            >
              <Icon svgClass="btnIcon" href="#BadgeMessageIcon" />
              <p>{tAchievements("sendFeedbackTitle")}</p>
            </button>

            <button
              type="button"
              className="customPopover__btn"
              onClick={() => {
                handleRequestFeedback(user.id, setIsAnimationActive);
              }}
            >
              <Icon svgClass="btnIcon" href="#FeedbackRequestIcon" />
              <p>{tAchievements("action_requestFeedback")}</p>
            </button>
          </>
        )}
      </StructureCard>
    );
  };

  return (
    <>
      <div className="structureBlock">
        <div className="d-flex structureHeader">
          <h1 className="main-title">{t("structureTitle")}</h1>

          <ul className="pageTabs">
            {tabs.map((tab) => (
              <li
                key={tab.value}
                className={classnames(tab.className, {
                  selected: activeTab === tab.value,
                })}
                onClick={() => handleChangeTab(tab.value)}
              >
                {tab.label}
              </li>
            ))}

            <Whisper
              speaker={speaker}
              ref={triggerRef}
              trigger="click"
              placement="bottomEnd"
            >
              <button
                type="button"
                className="pageTabs__link pageTabs__hiddenLink"
              >
                {tCommon("tabs_more")}
              </button>
            </Whisper>
          </ul>
        </div>

        {activeTab === StructureTabs.Manager ? (
          <div>
            {isLoading && (
              <div className="loader-container">
                <Loader size={LoaderSize.Small} />
              </div>
            )}

            {errorMessage && (
              <div className="new-wrapper error">{errorMessage}</div>
            )}

            {data && !data.basicInfo.manager && (
              <p className="no-content-message m-top">
                {t("emptyData_manager")}
              </p>
            )}

            {data && data.basicInfo.manager && (
              <div className="structureBlock__users">
                {StructureBlockCard(data.basicInfo.manager)}
              </div>
            )}
          </div>
        ) : (
          <div>
            {isUsersLoading && (
              <div className="loader-container">
                <Loader size={LoaderSize.Small} />
              </div>
            )}

            {errorUsers && (
              <div className="new-wrapper error">{errorUsers}</div>
            )}

            {usersData && usersData.length === 0 && (
              <p className="no-content-message m-top">
                {activeTab === StructureTabs.DirectReports
                  ? t("emptyData_directReports")
                  : t("emptyData_myTeam")}
              </p>
            )}

            {usersData && usersData.length !== 0 && (
              <div className="structureBlock__users">
                {usersData.map((user) => StructureBlockCard(user))}
              </div>
            )}
          </div>
        )}
      </div>
      <RequestFeedbackAnimation isAnimationActive={isAnimationActive} />

      <FeedbackRightPanel
        isModalOpen={isSendCheersModalOpen}
        onCloseModal={() => {
          setBadgeMessageReceiver(null);
          setIsSendCheersModalOpen(false);
        }}
        receiver={badgeMessageReceiver}
      />
    </>
  );
};

export default StructureBlock;
