import classNames from "classnames";
import { FieldProps, getIn } from "formik";
import { FC, useEffect, useRef, useState } from "react";

interface TextareaProps extends FieldProps {
  id?: string;
  rows: number;
  placeholder: string;
  maxLength: number;
  dynamicHeight?: boolean;
}

export const CustomTextarea: FC<TextareaProps> = ({
  id,
  rows,
  placeholder,
  field,
  maxLength,
  dynamicHeight = false,
  form: { touched, errors },
}) => {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const fieldValueLength: number = field.value.length;
  const [charCount, setCharCount] = useState<number>(fieldValueLength || 0);

  useEffect(() => {
    if (dynamicHeight && ref.current) {
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [dynamicHeight]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;
    const currentCharCount = inputText.length;

    // Limit the input to maxLength characters
    if (currentCharCount <= maxLength) {
      field.onChange(event);
      setCharCount(currentCharCount);
    }

    if (dynamicHeight && ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${event.target.scrollHeight}px`;
    }
  };

  const errorMessage = getIn(errors, field.name);
  // Check if the field has been touched and has an error
  const showError = touched[field.name] && errorMessage;

  return (
    <div className="form-style">
      <textarea
        {...field}
        id={id}
        ref={ref}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        className="form-textarea"
        onChange={handleChange}
      />
      <div
        className={classNames("form-textarea__footer", {
          "form-textarea__footer--between": showError,
        })}
      >
        {showError && <div className="error">{errorMessage}</div>}

        <div className="text-counter">
          <span>{charCount}/</span>
          <span>{maxLength}</span>
        </div>
      </div>
    </div>
  );
};
