import { AchievementsEventsStatuses } from "api/types/achievementEventsTypes";
import { AchievementFilterStatus } from "api/types/achievementTypes";

export const getCoinsComplete = (filterStatus: AchievementFilterStatus, applicantStatus?: AchievementsEventsStatuses | null) => {
  if (filterStatus !== AchievementFilterStatus.applied) {
    return;
  }

  if (applicantStatus === AchievementsEventsStatuses.OnReview) {
    return {
      renderWaitingIcon: true,
    }
  };
  
  return {
    className: "achievementCard__label--complete",
    renderCompleteIcon: true,
  };
};
