import { FieldProps } from "formik";
import { InputNumber } from "rsuite";

interface CustomInputNumberProps extends FieldProps {
  placeholder: string;
  disabled: boolean;
}

const CustomInputNumber = ({
  placeholder,
  form,
  field,
  disabled,
}: CustomInputNumberProps) => {
  const onChange = (value: string | number | null) => {
    if (value === null) return;

    // allow only numbers to input
    const allowedValue = String(value).replace(/[^0-9]/g, "");

    // convert to number only not empty string
    const numericValue = allowedValue ? +allowedValue : allowedValue;

    form.setFieldValue(field.name, numericValue);
  };

  return (
    <div className="customInputNumber">
      <InputNumber
        name={field.name}
        value={field.value}
        size="lg"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={form.handleBlur}
        scrollable={false}
        min={0}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomInputNumber;
