import { ClientDateRange } from "api/types/commonTypes";
import endOfMonth from "date-fns/endOfMonth";
import format from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";

const date = new Date();
const startOfMonthDate = startOfMonth(date);
const endOfMonthDate = endOfMonth(date);

export const currentMonth: ClientDateRange = [
  format(startOfMonthDate, "dd-MM-yyyy"),
  format(endOfMonthDate, "dd-MM-yyyy"),
];

export enum CounterType {
  birthdays = "birthdays",
  anniversaries = "anniversaries",
  holidays = "holidays",
  achievementCategory = "achievementCategory",
}

export const maxHeightBlock = 300;
