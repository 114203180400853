import { DateTimeTemplate } from "types/dateTemplateType";
import { subDays } from "date-fns";

export const NoImageUrl = "/img/no-image.png";
export const hostCompanyName = "Esteeme";

export const defaultColorScheme = {
  mainBgColor: "#f6f4fc",
  secondaryBgColor: "#dde0fb",
  formBgColor: "#f2f3fd",
  textButtonsColor: "#3e58f0",
  barChartMainColor: "#8884d8",
  barChartSecondaryColor: "#b0b5ff",
  boxShadowMainColor: "rgba(108,99,255, 0.3)",
  boxShadowSecondaryColor: "rgba(108,99,255, 0.1)",
};

export const EsteemePolicies = [
  {
    linkId: "termsOfUse",
    href: "https://esteeme.net/terms-of-use",
  },
  {
    linkId: "termsAndConditions",
    href: "https://esteeme.net/terms-and-conditions",
  },
  {
    linkId: "cookies",
    href: "https://esteeme.net/cookies-policy",
  },
  {
    linkId: "privacy",
    href: "https://esteeme.net/privacy-policy",
  },
];

export const ChartsColors = ["#D4EDFD", "#95D3FA", "#69AEDA"];

export const defaultCulture = "en-US";

export const countItemsPerPage = 20;
export const countReceivedItemsPerPage = 6;

export const countMaxAchievementParticipants = 5;

export const defaultLastMonthRange: [string, string] = [
  subDays(new Date(), 30).toString(),
  new Date().toString(),
];

// this template filed is stored on company google drive (accounts@keetcode.com)
export const templateFileWithCompanyUsers_url =
  "https://docs.google.com/spreadsheets/d/17hgdGlgEhKjVZ4AxKYRCvn7a0o1IYS3LecxsmYil8EE/edit?usp=sharing";

export const MAX_FILE_SIZE = 1024 * 1024 * 3; // 3MB (required size for uploaded files)
export const ALLOWED_FILE_EXTENSIONS = ["jpg", "png", "jpeg", "svg"];

export const defaultListState = {
  isLoading: false,
  data: null,
  errorMessage: "",
};

export const defaultStateWithInfiniteLoading = {
  isLoading: false,
  data: null,
  errorMessage: "",
  hasMoreData: true,
  page: 0,
};

export const extraUserCoinsCount = 200;
export const base_url = process.env.REACT_APP_API_BASE_URL;

export const hotjar_version = 6;

export const dateTimeTemplates: DateTimeTemplate = {
  en: {
    fullDateTime: "hh:mm a d MMMM yyyy",
    fullDate: "MMM d, yyyy",
    monthDayDate: "MMM d",
    monthYearDate: "MMMM, yyyy",
    shortFullDate: "PPP",
    monthDate: "MM",
    daysWeek: "EEE",
  },
  ru: {
    fullDateTime: "HH:mm:ss d MMMM yyyy",
    fullDate: "d MMM, yyyy",
    monthDayDate: "d MMM",
    monthYearDate: "MMMM, yyyy",
    shortFullDate: "PPP",
    monthDate: "MM",
    daysWeek: "EEE",
  },
  uk: {
    fullDateTime: "HH:mm:ss d MMMM yyyy",
    fullDate: "d MMM, yyyy",
    monthDayDate: "d MMM",
    monthYearDate: "MMMM, yyyy",
    shortFullDate: "PPP",
    monthDate: "MM",
    daysWeek: "EEE",
  },
};

export const achievementCategoryDefaultColor= "soft_blue";
